import moment from 'moment';

export const durationFormatter = (value: number, format: string) => {
  let minutes;
  let seconds;
  if (format === 'msToMM:SS') {
    minutes = Math.floor(value / 60000);
    seconds = Math.floor((value - minutes * 60000) / 1000);
  }
  return `${minutes}:${seconds && seconds < 10 ? `0${seconds}` : seconds}`;
};

export const averageTimeConverter = (value: number) => {
  let days = 0;

  if (value > 24) {
    days = Math.floor(value / 24);
  }
  const hours = Math.floor(value - days * 24);
  const duration = moment.duration(value - days * 24 - hours, 'hours');
  const remainingMinutes = Math.floor(duration.asMinutes());
  let display;
  if (days > 0) {
    display = `${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${
      hours === 1 ? 'hour' : 'hours'
    }, ${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
  } else if (days === 0 && hours > 0) {
    display = `${hours} ${hours === 1 ? 'hour' : 'hours'} ${remainingMinutes} ${
      remainingMinutes === 1 ? 'minute' : 'minutes'
    }`;
  } else {
    display = `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
  }

  return display;
};
