export const currencySymbol = (value: string) => {
  let currency = '£';
  switch (value) {
    case 'GBP':
      currency = '£';
      break;
    case 'USD':
      currency = '$';
      break;
  }
  return currency;
};
